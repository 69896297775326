import React from "react";
import '../styles/long1.css';
import '../styles/gogogo.css';
import SEO from "../components/SEO";


const IndexPage = () => (
    <div className="grid-container">
    <div className="header">
        <div className="header1"> 
            <div className="logo-div go-logo-div">
                <a aria-current="page" className="logo" href="/"><div className="driverprofile-logo"></div></a>
            </div>
        </div>
        <a href="https://www.texpresslanes.com/" target="_blank"><div className="header2">What are TEXpress Lanes</div></a>
        <a href="https://www.texpresslanes.com/maps/texpress-lanes-map" target="_blank"><div className="header3">Where are TEXpress Lanes</div></a>
        <a href="https://www.texpresslanes.com/navigating-texpress-lanes/how-to-use" target="_blank"><div className="header4">How to Use TEXpress Lanes</div></a>
   </div>
    <div className="left_space"></div>
    <div className="short1-main">
        <div className="gogogo-ty-bg">
          <div className="go-headSection">
            <p className="gopage-end-header">The sweepstakes entry period has ended.</p>
            <p className="gopage-end-text">Thanks for participating in the TEXpress Lanes Holiday Sweepstakes. This sweepstakes is now closed. After entry verification, 5 winners will be notified the week of January 17, 2022.<br /><br />Best of luck to everyone who entered!</p>
        </div>
        </div>
    </div>  
  
    <div className="right_space"></div>
    <div className="footer">
            <div className="register-footer1"><a href="https://www.facebook.com/TEXpressLanes" target="_blank"><img className="fb-icon"
  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"/></a> | <a href="https://twitter.com/texpresslanes" target="_blank"><img className="twitter-icon"
  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"/></a> |  <a href="https://www.texpresslanes.com/privacy-policy" target="_blank">Privacy Policy</a> │ ©2021 LBJ/NTE/NTE 35W TEXpress Lanes | All Rights Reserved</div>
        </div>
</div>
);

export default IndexPage;
